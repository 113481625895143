import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" isIndex={false}/>
    <section className="container top-container">
      <h1 className="top-h">404 page not found...</h1>
      <p className="text-center">
        株式会社DanRanのサイトへお越し頂き、ありがとうございます。
      </p>
      <p className="text-center">
        お探しのページまたはファイルがみつかりませんでした。
        <br />
        URLをご確認いただくか、メニューからページをお探しください。
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
